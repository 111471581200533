import React from 'react';

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            src="/static/images/Repos_Black_A.png"
            style={{width: '117px', borderRadius: 3, height: '38.92px'}}
            {...props}
        />
    );
}

export default Logo;