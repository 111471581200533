import * as actionTypes from '../actions';

var initialState = {
    notificationLoading: false,
    notify_list: [],
    new_item: [],
    IsNetworkError: false
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATION_LIST: {
            return {
                ...state,
                new_item: action?.payload?.results?.filter(value => state?.notify_list.includes(value)),
                notify_list: action?.payload?.results,
                notificationLoading: false
            };
        }
        case actionTypes.GET_NOTIFICATION_LOADING: {
            return {
                ...state,
                notificationLoading: true,
            };
        }
        case actionTypes.OFFLINE: {
            return {
                ...state,
                IsNetworkError: true,
            };
        }
        case actionTypes.ONLINE: {
            return {
                ...state,
                IsNetworkError: false,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default notificationReducer;