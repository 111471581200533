export const animationData =
    {
        "nm": "InfinityLoading01",
        "ddd": 0,
        "h": 500,
        "w": 500,
        "meta": {
            "g": "LottieFiles AE 1.0.0",
            "tc": "#ffffff"
        },
        "layers": [
            {
                "ty": 4,
                "nm": "InfinitySign Outlines 2",
                "sr": 1,
                "st": 0,
                "op": 49,
                "ip": 0,
                "hd": false,
                "ddd": 0,
                "bm": 0,
                "hasMask": false,
                "ao": 0,
                "ks": {
                    "a": {
                        "a": 0,
                        "k": [
                            400,
                            400,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    },
                    "sk": {
                        "a": 0,
                        "k": 0
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            250,
                            250,
                            0
                        ],
                        "ix": 2
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "sa": {
                        "a": 0,
                        "k": 0
                    },
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    }
                },
                "ef": [],
                "shapes": [
                    {
                        "ty": "gr",
                        "bm": 0,
                        "hd": false,
                        "mn": "ADBE Vector Group",
                        "nm": "Group 1",
                        "ix": 1,
                        "cix": 2,
                        "np": 2,
                        "it": [
                            {
                                "ty": "sh",
                                "bm": 0,
                                "hd": false,
                                "mn": "ADBE Vector Shape - Group",
                                "nm": "Path 1",
                                "ix": 1,
                                "d": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "c": true,
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                34.909
                                            ],
                                            [
                                                -34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                34.909
                                            ],
                                            [
                                                34.909,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                -34.909
                                            ],
                                            [
                                                55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                -34.909
                                            ],
                                            [
                                                -55.164,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -90.791,
                                                63.209
                                            ],
                                            [
                                                -154,
                                                0
                                            ],
                                            [
                                                -90.791,
                                                -63.209
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                90.791,
                                                63.209
                                            ],
                                            [
                                                154,
                                                0
                                            ],
                                            [
                                                90.791,
                                                -63.209
                                            ]
                                        ]
                                    },
                                    "ix": 2
                                }
                            },
                            {
                                "ty": "st",
                                "bm": 0,
                                "hd": false,
                                "mn": "ADBE Vector Graphic - Stroke",
                                "nm": "Stroke 1",
                                "lc": 2,
                                "lj": 1,
                                "ml": 10,
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 18,
                                    "ix": 5
                                },
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.0196,
                                        0.5804,
                                        0.5725
                                    ],
                                    "ix": 3
                                }
                            },
                            {
                                "ty": "tr",
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        400,
                                        400
                                    ],
                                    "ix": 2
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                }
                            }
                        ]
                    },
                    {
                        "ty": "tm",
                        "bm": 0,
                        "hd": false,
                        "mn": "ADBE Vector Filter - Trim",
                        "nm": "Trim Paths 1",
                        "ix": 2,
                        "e": {
                            "a": 0,
                            "k": 55,
                            "ix": 2
                        },
                        "o": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 0
                                },
                                {
                                    "s": [
                                        720
                                    ],
                                    "t": 48
                                }
                            ],
                            "ix": 3
                        },
                        "s": {
                            "a": 0,
                            "k": 35,
                            "ix": 1
                        },
                        "m": 1
                    }
                ],
                "ind": 1
            },
            {
                "ty": 4,
                "nm": "InfinitySign Outlines",
                "sr": 1,
                "st": 0,
                "op": 49,
                "ip": 0,
                "hd": false,
                "ddd": 0,
                "bm": 0,
                "hasMask": false,
                "ao": 0,
                "ks": {
                    "a": {
                        "a": 0,
                        "k": [
                            400,
                            400,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    },
                    "sk": {
                        "a": 0,
                        "k": 0
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            250,
                            250,
                            0
                        ],
                        "ix": 2
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "sa": {
                        "a": 0,
                        "k": 0
                    },
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    }
                },
                "ef": [],
                "shapes": [
                    {
                        "ty": "gr",
                        "bm": 0,
                        "hd": false,
                        "mn": "ADBE Vector Group",
                        "nm": "Group 1",
                        "ix": 1,
                        "cix": 2,
                        "np": 2,
                        "it": [
                            {
                                "ty": "sh",
                                "bm": 0,
                                "hd": false,
                                "mn": "ADBE Vector Shape - Group",
                                "nm": "Path 1",
                                "ix": 1,
                                "d": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "c": true,
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                34.909
                                            ],
                                            [
                                                -34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                34.909
                                            ],
                                            [
                                                34.909,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                -34.909
                                            ],
                                            [
                                                55.164,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                34.909,
                                                0
                                            ],
                                            [
                                                0,
                                                -34.909
                                            ],
                                            [
                                                -55.164,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                -90.791,
                                                63.209
                                            ],
                                            [
                                                -154,
                                                0
                                            ],
                                            [
                                                -90.791,
                                                -63.209
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                90.791,
                                                63.209
                                            ],
                                            [
                                                154,
                                                0
                                            ],
                                            [
                                                90.791,
                                                -63.209
                                            ]
                                        ]
                                    },
                                    "ix": 2
                                }
                            },
                            {
                                "ty": "st",
                                "bm": 0,
                                "hd": false,
                                "mn": "ADBE Vector Graphic - Stroke",
                                "nm": "Stroke 1",
                                "lc": 2,
                                "lj": 1,
                                "ml": 10,
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 5
                                },
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.8863,
                                        0.9255,
                                        0.9255
                                    ],
                                    "ix": 3
                                }
                            },
                            {
                                "ty": "tr",
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        400,
                                        400
                                    ],
                                    "ix": 2
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                }
                            }
                        ]
                    },
                    {
                        "ty": "tm",
                        "bm": 0,
                        "hd": false,
                        "mn": "ADBE Vector Filter - Trim",
                        "nm": "Trim Paths 1",
                        "ix": 2,
                        "e": {
                            "a": 0,
                            "k": 100,
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "m": 1
                    }
                ],
                "ind": 2
            }
        ],
        "v": "4.8.0",
        "fr": 24,
        "op": 48,
        "ip": 0,
        "assets": []
    }