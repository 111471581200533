import {reposWebApi} from "./customerAPI";
import {refreshToken} from "./sessionAction";
import qs from "querystring";
import secureLocalStorage from "react-secure-storage";

export const ASSET_FOR_COMPLAINT_LOADING = 'ASSET_FOR_COMPLAINT_LOADING'
export const GET_ASSET_LIST_FOR_COMPLAINT = 'GET_ASSET_LIST_FOR_COMPLAINT';
export const GET_ASSET_LIST_FOR_COMPLAINT_FAIL = 'GET_ASSET_LIST_FOR_COMPLAINT_FAIL';
export const TICKET_DETAILS_LOADING = 'TICKET_DETAILS_LOADING'
export const TICKET_DETAILS = 'TICKET_DETAILS';
export const TICKET_DETAILS_FAIL = "TICKET_DETAILS_FAIL";
export const RATING_DETAILS_LOADING = "RATING_DETAILS_LOADING";
export const RATING_DETAILS = "RATING_DETAILS";
export const RATING_DETAILS_FAIL = "RATING_DETAILS_FAIL"
export const getAssetForComplaint = (formData) => {
    return (dispatch) => {
        dispatch({
            type: ASSET_FOR_COMPLAINT_LOADING,
        })
        reposWebApi.get('/customer/get_on_the_go_customer_assets', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
            params: formData
        })
            .then(response => {
                dispatch({
                    type: GET_ASSET_LIST_FOR_COMPLAINT,
                    payload: response?.data?.data
                });
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    dispatch(getAssetForComplaint(formData))
                } else dispatch({
                    type: GET_ASSET_LIST_FOR_COMPLAINT_FAIL,
                    payload: error
                })
            });
    }
};

export const getTicketDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: TICKET_DETAILS_LOADING,
        })
        reposWebApi.post('/customer/v2/get_status', {id: id}, {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        })
            .then(response => {
                dispatch({
                    type: TICKET_DETAILS,
                    payload: response?.data?.data
                });
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    //dispatch(getTicketDetails(formData))
                } else dispatch({
                    type: TICKET_DETAILS_FAIL,
                    payload: error
                })
            });
    }
};


export const getRatingDetails = (formData) => {
    return (dispatch) => {
        dispatch({
            type: RATING_DETAILS_LOADING,
        })
        reposWebApi.post('/customer/v2/get_rating', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        })
            .then(async response => {
                dispatch({
                    type: RATING_DETAILS,
                    payload: response?.data?.data
                });
                await dispatch(getTicketDetails(formData.id))
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken())
                    //dispatch(getTicketDetails(formData))
                } else dispatch({
                    type: RATING_DETAILS_FAIL,
                    payload: error
                })
            });
    }
};

