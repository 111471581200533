import {reposWebApi} from "./customerAPI";
import {OFFLINE, ONLINE} from "./orderActions";
import secureLocalStorage from "react-secure-storage";

export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";


// *********************** Notification List ******************

export const getNotification = (enqueueSnackbar) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NOTIFICATION_LOADING,
        })
        await reposWebApi.get('/customer/list_notification', {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            dispatch({
                type: GET_NOTIFICATION_LIST,
                payload: response?.data?.data
            });
        }).catch(async error => {
            console.log(error);
            // if (error?.response?.status === 401) {
            //     await dispatch(refreshToken())
            // }
        });
    }
};

export const getNetworkError = (online) => {
    return async (dispatch) => {
        if (!online) {
            dispatch({
                type: ONLINE,
            })
        } else {
            dispatch({
                type: OFFLINE,
            })
        }
    }
};