import {combineReducers} from 'redux';
import sessionReducer from './sessionReducer';
import assetsReducer from './assetReducer';
import assetReducer from './assetReducer';
import orderReducer from './orderReducer'
import reponsiveReducer from "./reponsiveReducer";
import paymentReducer from "./paymentReducer";
import datumReducer from "./datumReducer";
import energyReducer from './energyReducer';
import notificationReducer from "./notificationReducer";
import complaintReducer from "./complaintReducer"
import tataReducer from "./tataReducer";

const rootReducer = combineReducers({
    session: sessionReducer,
    complaint: complaintReducer,
    getAssets: assetsReducer,
    getOrder: orderReducer,
    assetReducer: assetReducer,
    paymentReducer: paymentReducer,
    getWindowSize: reponsiveReducer,
    getDatum: datumReducer,
    getEnergy: energyReducer,
    getNotify: notificationReducer,
    getProduct : tataReducer
});

export default rootReducer;