import React from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button, ListItem} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {colorIcon} from "../../../../actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto',
        fontSize: '15px'
    },
    active: {
        color: "#161616",
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '17px'
        },
        '& $icon': {
            color: "#161616",
        }
    }
}));

function ExpandLessIcon() {
    return null;
}

const NavItem = ({
                     children,
                     className,
                     depth,
                     href,
                     icon: Icon,
                     info: Info,
                     open: openProp,
                     title,
                     play,
                     ...rest
                 }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {paddingLeft};

    const handleChangesIconColor = () => {
        dispatch(colorIcon(title));
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >

            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                component={RouterLink}
                onClick={handleChangesIconColor}
                onClickCapture={play}
                exact
                style={style}
                to={href}
            >
                {Icon && (
                    <Icon
                        className={classes.icon}
                        size="20"
                        colorCode={"#161616"}
                    />
                )}
                <span className={classes.title}>
          {title}
        </span>
                {Info && <Info/>}
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
