export const LOGIN_WINDOW_SIZE = 'LOGIN_WINDOW_SIZE';

export const LoginResponsive = (theme, small) => {
    let size;
    if (small) {
        size = 'sm'
    } else {
        size = 'gt'
    }
    return (dispatch) => {
        dispatch({
            type: LOGIN_WINDOW_SIZE,
            payload: size
        });
    }
};




