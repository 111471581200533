import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const MyAssets = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    return (
        <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <path d="M0.675049 5.89581H10.1191" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                  strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M9.44425 9.04041V10.2196C9.44425 10.3238 9.40871 10.4238 9.34546 10.4975C9.2822 10.5712 9.19641 10.6127 9.10696 10.6127H8.0951C8.00565 10.6127 7.91985 10.5712 7.8566 10.4975C7.79335 10.4238 7.75781 10.3238 7.75781 10.2196V9.04041"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M3.03604 9.04041V10.2196C3.03604 10.3238 3.00051 10.4238 2.93725 10.4975C2.874 10.5712 2.78821 10.6127 2.69876 10.6127H1.6869C1.59744 10.6127 1.51165 10.5712 1.4484 10.4975C1.38514 10.4238 1.34961 10.3238 1.34961 10.2196V9.04041"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M2.69849 7.46826H3.37306" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                  strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.42065 7.46826H8.09523" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                  strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M9.44449 5.89595L8.18388 2.59424C8.15773 2.52439 8.11471 2.4649 8.06006 2.42303C8.00542 2.38116 7.94151 2.3587 7.87611 2.3584H2.91799C2.8526 2.3587 2.78868 2.38116 2.73404 2.42303C2.6794 2.4649 2.63638 2.52439 2.61022 2.59424L1.34961 5.89595V9.04045H9.44449V5.89595Z"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M15.0006 16.6445H5.86793V8.49682C5.86723 8.29596 5.89971 8.09693 5.9635 7.9112C6.02729 7.72547 6.12113 7.55673 6.23961 7.4147C6.35808 7.27267 6.49884 7.16018 6.65376 7.0837C6.80869 7.00723 6.97471 6.96829 7.14226 6.96913H13.7263C13.8938 6.96829 14.0598 7.00723 14.2148 7.0837C14.3697 7.16018 14.5105 7.27267 14.6289 7.4147C14.7474 7.55673 14.8412 7.72547 14.905 7.9112C14.9688 8.09693 15.0013 8.29596 15.0006 8.49682V16.6445Z"
                fill="#F5F5F5"/>
            <path d="M6.80835 9.53949H14.06" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                  strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.80835 11.8977H14.06" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                  strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M14.06 15.4355H6.80836V9.14654C6.8078 8.99151 6.83359 8.83788 6.88425 8.69452C6.9349 8.55116 7.00941 8.42091 7.10348 8.31128C7.19755 8.20166 7.30932 8.11482 7.43234 8.05579C7.55535 7.99676 7.68718 7.96671 7.82022 7.96736H13.0482C13.1812 7.96671 13.313 7.99676 13.436 8.05579C13.5591 8.11482 13.6708 8.20166 13.7649 8.31128C13.859 8.42091 13.9335 8.55116 13.9841 8.69452C14.0348 8.83788 14.0606 8.99151 14.06 9.14654V15.4355Z"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M14.06 15.4351V16.6143C14.06 16.7186 14.0244 16.8185 13.9612 16.8922C13.8979 16.966 13.8121 17.0074 13.7227 17.0074H12.7108C12.6214 17.0074 12.5356 16.966 12.4723 16.8922C12.4091 16.8185 12.3735 16.7186 12.3735 16.6143V15.4351"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M8.49478 15.4351V16.6143C8.49478 16.7186 8.45925 16.8185 8.39599 16.8922C8.33274 16.966 8.24695 17.0074 8.1575 17.0074H7.14564C7.05618 17.0074 6.97039 16.966 6.90714 16.8922C6.84389 16.8185 6.80835 16.7186 6.80835 16.6143V15.4351"
                stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M8.57966 14.256C8.85908 14.256 9.08559 13.9921 9.08559 13.6664C9.08559 13.3408 8.85908 13.0768 8.57966 13.0768C8.30024 13.0768 8.07373 13.3408 8.07373 13.6664C8.07373 13.9921 8.30024 14.256 8.57966 14.256Z"
                fill="#737373"/>
            <path
                d="M12.2891 14.256C12.5686 14.256 12.7951 13.9921 12.7951 13.6664C12.7951 13.3408 12.5686 13.0768 12.2891 13.0768C12.0097 13.0768 11.7832 13.3408 11.7832 13.6664C11.7832 13.9921 12.0097 14.256 12.2891 14.256Z"
                fill="#737373"/>
        </svg>

    )
}

MyAssets.propTypes = {
    fill: PropTypes.string
};

export default MyAssets
