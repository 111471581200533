export const WINDOW_SIZE = 'WINDOW_SIZE';
export const GET_COLOR = 'GET_COLOR';

export const responsive = (theme, greater, greaterThanMid, smallToMid, lessThanSmall, small) => {
    let size;
    if (greaterThanMid) {
        size = 'md'
    } else if (smallToMid) {
        size = 'md'
    } else if (lessThanSmall) {
        size = 'sm'
    } else if (small) {
        size = 'xs'
    } else if (greater) {
        size = 'sm'
    }
    return (dispatch) => {
        dispatch({
            type: WINDOW_SIZE,
            payload: size
        });
    }
};

export const colorIcon = (color) => {
    return (dispatch) => {
        dispatch({
            type: GET_COLOR,
            payload: color
        });
    }
};




