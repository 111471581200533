import React from 'react'
import BellIcon from "../../../../assets/svgs/BellIcon"
import {IconButton, SvgIcon, Tooltip} from '@mui/material'


const Notification = () => {
    return (
        <>
            <Tooltip title="Notifications">
                <IconButton color="inherit">
                    <SvgIcon>
                        <BellIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Notification