import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const MyPayments = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    return (
        <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <path
                d="M3.53567 6.16939C3.15038 6.14211 2.7365 6.05352 2.34755 5.66409C2.01304 5.32959 1.82843 4.88489 1.82843 4.41195C1.82843 3.93902 2.01304 3.49372 2.34755 3.15922C3.33913 2.1687 4.48603 2.87964 4.93368 3.23127C5.0707 3.33923 5.2239 3.47069 5.3876 3.61739C5.54777 3.75535 5.75672 3.84074 5.9861 3.84074C6.22233 3.84074 6.43777 3.75027 6.60018 3.60262C6.62971 3.5731 6.65876 3.54262 6.68829 3.51215C6.82212 3.35305 6.90279 3.14812 6.90279 2.92299C6.90279 2.68357 6.81007 2.46576 6.65876 2.30241C6.44604 2.11 6.24394 1.93613 6.06358 1.79451C4.38457 0.473979 2.41865 0.502563 1.05383 1.86644C0.37465 2.54655 0 3.45037 0 4.41195C0 5.37341 0.37465 6.27735 1.05383 6.95699C1.74917 7.65174 2.59996 8.00018 3.49043 8.00018C4.34677 8.00018 5.23996 7.67714 6.06358 7.02892C6.63892 6.57677 7.41588 5.80442 8.13154 5.07127C8.81343 4.37037 9.5494 3.63865 10.067 3.23127C10.3351 3.02079 10.8551 2.68073 11.4424 2.65545C7.57274 2.70896 6.53923 6.37692 3.53567 6.16939Z"
                fill={window.color === 'My Payments' ? "#161616" : "#737373"}/>
            <path
                d="M13.9464 1.86636C13.2516 1.17148 12.4002 0.823159 11.5107 0.823159C10.6534 0.823159 9.7607 1.14656 8.93708 1.79442C8.35017 2.25578 7.55253 3.04751 6.82482 3.794C6.15487 4.48108 5.4411 5.19343 4.93415 5.5916C4.66189 5.80527 4.13214 6.1517 3.53613 6.1693C6.53969 6.37683 7.5732 2.70887 11.442 2.65537C11.8342 2.63824 12.2568 2.76191 12.6536 3.15913C12.9881 3.49363 13.1722 3.93893 13.1722 4.41186C13.1722 4.8848 12.9881 5.3295 12.6536 5.66401C12.255 6.06217 11.8328 6.18537 11.4392 6.16742C11.4051 6.16553 11.371 6.16375 11.3377 6.15997C11.3335 6.15962 11.3303 6.15915 11.3272 6.15867C11.2934 6.15501 11.2602 6.15076 11.226 6.14485C11.1924 6.1393 11.1587 6.13186 11.1259 6.12454C11.1222 6.12359 11.1181 6.12265 11.1144 6.12218C11.0498 6.10694 10.9852 6.08851 10.9234 6.06772C10.9197 6.06643 10.916 6.06548 10.9128 6.06359C10.8814 6.05343 10.8509 6.04186 10.8204 6.0304H10.8201C10.7896 6.01882 10.7606 6.00595 10.731 5.99343C10.7287 5.99213 10.7259 5.99119 10.7226 5.99024C10.6945 5.97737 10.6659 5.9639 10.6378 5.95055C10.6355 5.94961 10.6332 5.94867 10.6309 5.94737C10.6037 5.9339 10.5769 5.92103 10.5505 5.90721C10.5496 5.90673 10.5488 5.90579 10.5478 5.90532C10.3499 5.80102 10.1852 5.68385 10.0675 5.5916C9.92311 5.47809 9.76023 5.33872 9.58672 5.1828C9.43081 5.06008 9.23426 4.98579 9.02012 4.98579C8.51352 4.98579 8.10295 5.396 8.10295 5.9026C8.10295 6.15076 8.20252 6.37412 8.36174 6.53889C8.56703 6.72527 8.7618 6.89134 8.93708 7.02883C10.6161 8.34984 12.5821 8.32113 13.9464 6.9569C14.626 6.27726 15.0002 5.37332 15.0002 4.41186C15.0002 3.45028 14.626 2.54646 13.9464 1.86636Z"
                fill={window.color === 'My Payments' ? "#161616" : "#737373"}/>
        </svg>
    )
}

MyPayments.propTypes = {
    fill: PropTypes.string
};

export default MyPayments
