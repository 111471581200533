import * as actionTypes from '../actions';

var initialState = {
    isLoading: false,
    sessionExpired: false,
    allTranscation: [],
    allSellers: [],
    Transcationcount: 0,
    Sellerscount: 0,
    getTranscationError: '',
    getSellersError: '',
    graphDetails: [],
    graphDetailsError: '',
    transactionNext: '',
    isFaild: false,
    getWayHDFC: [],
    isHDFC: false,
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAYMENT_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,
            };
        }
        case actionTypes.PAYMENT_HDFC: {
            return {
                ...state,
                isHDFC: true,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_TRANSCATION_LIST: {
            return {
                ...state,
                isLoading: false,
                allTranscation: state?.allTranscation?.concat(action.payload?.results),
                Transcationcount: action.payload?.count,
                sessionExpired: false,
                transactionNext: action?.payload?.next
            };
        }
        case actionTypes.GET_TRANSCATION_LIST_FAIL: {
            return {
                ...state,
                getTranscationError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_SELLERS_LIST: {
            return {
                ...state,
                isLoading: false,
                allSellers: action.payload.results,
                Sellerscount: action.payload.count,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_SELLERS_LIST_FAIL: {
            return {
                ...state,
                getSellersError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_GRAPH_DETAILS: {
            return {
                ...state,
                graphDetails: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_GRAPH_DETAILS_FAIL: {
            return {
                ...state,
                graphDetailsError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_PAYMENT_BEHAVIOUR: {
            return {
                ...state,
                paymentBehaviour: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_PAYMENT_BEHAVIOUR_FAIL: {
            return {
                ...state,
                paymentBehaviourError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_ORDER_REPORT: {
            return {
                ...state,
                paymentBehaviour: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_ORDER_REPORT_FAIL: {
            return {
                ...state,
                paymentBehaviourError: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_HDFC_URL_DATA: {
            return {
                ...state,
                getWayHDFC: action.payload,
                isHDFC: false,
                sessionExpired: false,
                isFaild: true,
            };
        }
        case actionTypes.GET_HDFC_URL_FAIL: {
            return {
                ...state,
                isFaild: true,
                sessionExpired: false,
                isHDFC: false,
            };
        }
        case actionTypes.CLEAR_TRANSATIONS: {
            return {
                ...state,
                isLoading: true,
                allTranscation: [],
            };
        }
        default: {
            return state;
        }
    }
};

export default paymentReducer;
