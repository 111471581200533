import React from 'react'
import PropTypes from 'prop-types';

const ProfileIcon = ({style, className, ...props}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 11.25C11.4853 11.25 13.5 9.23528 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75C4.5 9.23528 6.51472 11.25 9 11.25Z"
                stroke="#737373" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path
                d="M2.17969 15.1875C2.87081 13.9902 3.86496 12.9959 5.06219 12.3046C6.25941 11.6133 7.61753 11.2494 9 11.2494C10.3825 11.2494 11.7406 11.6133 12.9378 12.3046C14.135 12.9959 15.1292 13.9902 15.8203 15.1875"
                stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

ProfileIcon.propTypes = {
    fill: PropTypes.string
};

export default ProfileIcon
