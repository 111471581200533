import * as actionTypes from "../actions";
import {DATA_FOR_PRODUCT,GET_PRODUCT_PAYMENT} from "../actions/TataAction";
import {PRODUCT_PAYMENT_LOADING} from "../actions";


var initialState = {
    isLoading: true,
    product: null,
    orderPaymentInfo: "",
    orderNumber:"",
    isPayLoading: true,
};

const tataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_FOR_PRODUCT: {
            return {
                ...state,
                isLoading: false,
                product: action?.payload
            };
        }
        case actionTypes.PRODUCT_DETAIL_SENT:{
            return{
                ...state,
                isLoading: false,

            }
        }
        case actionTypes.PRODUCT_DETAIL_SENT_FAIL:{
            return{
                ...state,
                isLoading: false,

            }
        }
        case actionTypes.GET_PRODUCT_PAYMENT:{
            return{
                ...state,
                orderPaymentInfo: action.payload?.data,
                isPayLoading: false,
             }

        }
        case actionTypes.PRODUCT_PAYMENT_LOADING:{
            return{
                ...state,
                isPayLoading:true,
            }
        }
        case actionTypes.UPDATE_RATE:{
            return{
                ...state,
                isLoading: false
            }
        }
        case actionTypes.UPDATE_RATE_FAIL:{
            return{
                ...state,
                isLoading: false
            }
        }
        case actionTypes.UPDATE_LOADING:{
            return{
                ...state,
                isLoading: true
            }
        }
        default: {
            return state;
        }
    }
}

export default tataReducer;