import {reposWebApi} from "./customerAPI";
import qs from "querystring";
import {refreshToken} from "./sessionAction";
import secureLocalStorage from "react-secure-storage";

export const GET_ENERGY_SCORE = "GET_ENERGY_SCORE";
export const GET_ENERGY_SCORE_FAIL = "GET_ENERGY_SCORE_FAIL";
export const GET_ENERGY_SCORE_LOADING = "GET_ENERGY_SCORE_LOADING";

//export const SESSION_EXPIRED = 'SESSION_EXPIRED';


// *********************** Actyv Integration ******************

export const getEnergyScore = (formData) => {
    return async (dispatch) => {
        dispatch({
            type: GET_ENERGY_SCORE_LOADING,
        })
        await reposWebApi.post('/utilities/get/actvy/signup_url', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            },
        }).then(response => {
            dispatch({
                type: GET_ENERGY_SCORE,
                payload: response?.data?.signUpUrl
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken())
            } else dispatch({
                type: GET_ENERGY_SCORE_FAIL,
                payload: error
            })
        });
    }
};