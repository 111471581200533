import React from 'react'
import ProfileIcon from "../../../../assets/svgs/ProfileIcon"
import {IconButton, SvgIcon, Tooltip} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom';


const Profile = () => {
    return (
        <>
            <Tooltip title="Profile">
                <IconButton color="inherit"
                            component={RouterLink}
                            to={"/Profile"}>
                    {/* onBoarding login  Profile*/}
                    <SvgIcon>
                        <ProfileIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Profile