import React from 'react';
import {makeStyles} from "@mui/styles";
import Lottie from 'react-lottie';
import {animationData} from "../animations/SplashScreen.js"

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        padding: theme.spacing(3),
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000
    }
}));

const SlashScreen = () => {
    const classes = useStyles();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: 'svg'
    }

    return (
        <div className={classes.root}>
            <Lottie
                options={defaultOptions}
                height={500}
                width={500}
            />
        </div>
    );
}

export default SlashScreen;