import fernet from "fernet";

const encryptedData = (data) => {

    let secret = new fernet.Secret('BbSePhsFh4eJ5U5hUkfTm_Y1R1wS4PhWnLltHUPiMW8');
    let token = new fernet.Token({
        secret: secret,
        // time: Date.parse(1),
        // iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    })

    token.encode("string");

    let toke = new fernet.Token({
        secret: secret,
        token: token.encode(data),
        ttl: 0
    });

    return token.encode(data)
}
export default encryptedData