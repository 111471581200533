import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const MyOrders = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    return (
        <svg
            width="15"
            height="20"
            viewBox="0 0 15 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <path
                d="M10.75 3.46991H13.35C13.5224 3.46991 13.6877 3.53938 13.8096 3.66305C13.9315 3.78671 14 3.95444 14 4.12933V17.9772C14 18.152 13.9315 18.3198 13.8096 18.4434C13.6877 18.5671 13.5224 18.6366 13.35 18.6366H1.65C1.47761 18.6366 1.31228 18.5671 1.19038 18.4434C1.06848 18.3198 1 18.152 1 17.9772V4.12933C1 3.95444 1.06848 3.78671 1.19038 3.66305C1.31228 3.53938 1.47761 3.46991 1.65 3.46991H4.25"
                stroke={window.color === 'My Orders' ? "#161616" : "#737373"} strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M4.25 5.33345V4.61123C4.25 3.65351 4.59241 2.73501 5.2019 2.05779C5.8114 1.38058 6.63805 1.00012 7.5 1.00012C8.36195 1.00012 9.1886 1.38058 9.7981 2.05779C10.4076 2.73501 10.75 3.65351 10.75 4.61123V5.33345H4.25Z"
                stroke={window.color === 'My Orders' ? "#161616" : "#737373"} strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

    )
}

MyOrders.propTypes = {
    fill: PropTypes.string
};

export default MyOrders
