import useScrollReset from "../hooks/useScrollReset";


const ScrollReset = () => {
    useScrollReset();

    return null;
};

export default ScrollReset;
