import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// Utilities
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

// Material Components
import {AppBar, Box, Fab, Hidden, IconButton, Paper, Toolbar, Typography} from '@mui/material';
import MyPayments from "../../../../assets/svgs/MyPayments";
import MyAssets from "../../../../assets/svgs/MyAssets";
import {styled} from "@mui/material/styles";
import HomeIcon from "../../../../assets/svgs/HomeIcon";
import MyOrders from "../../../../assets/svgs/MyOrders";
import clsx from "clsx";
import OrderDialog from "../PlaceOrderDialog";
import {colorIcon} from "../../../../actions";
import {useDispatch} from "react-redux";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
    root: {},
    navBar: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        display: 'flex !important',
        maxHeight: "70px"
    }

}));
const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const BottomBar = ({className, onMobileClose, openMobile, play, ...rest}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    const [button, setButton] = useState(0)

    const handleChange = (e, button, title) => {
        setButton(button);
        dispatch(colorIcon(title));
    }

    const handleOpenDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Hidden lgUp>
            <AppBar color="primary" sx={{top: 'auto', bottom: 0}}
                    className={clsx(classes.root, classes.appBarTransparent, className)}
                    style={{backgroundColor: "#ffffff"}} {...rest}>
                <div className={classes.navBar}>
                    <Toolbar>
                        <Box display="flex"
                             flexDirection="row"
                             style={{
                                 flex: 2,
                                 flexDirection: "row",
                                 justifyContent: 'space-between',
                                 padding: 10,
                                 paddingLeft: "36px"
                             }}
                        >
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={1}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: button === 1 ? "black" : ''
                                    }}
                                    component={RouterLink} to={"/dashboard"} onClickCapture={play}
                                    onClick={e => handleChange(e, 1, "Home")}>
                                    <HomeIcon style={{color: "black"}}/>
                                    <Typography variant={"body2"}>
                                        Home
                                    </Typography>
                                </IconButton>
                            </Box>
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={2}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: button === 2 ? "black" : ''
                                    }}
                                    component={RouterLink} to={"/myOrders"} onClickCapture={play}
                                    onClick={e => handleChange(e, 2, 'My Orders')}>
                                    <MyOrders/>
                                    <Typography variant={"body2"}>
                                        Orders
                                    </Typography>
                                </IconButton>
                            </Box>
                            <StyledFab color="secondary" aria-label="add">
                                <IconButton
                                    id={3}
                                    onClickCapture={play}
                                    onClick={handleOpenDialog}
                                >
                                    <Paper elevation={0}
                                           style={{
                                               borderRadius: "100px",
                                               boxShadow: " 0px 4px 34px rgb(74 154 159 / 40%)",
                                               background: "rgba(74, 154, 159, 0.2)"
                                           }}>
                                        <img
                                            alt="Mission_Plant"
                                            src="/static/images/navBarMobile/add-newnewOrde.png"
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                borderRadius: "100px",
                                            }}
                                        />
                                    </Paper>
                                </IconButton>
                            </StyledFab>
                            <Box component="span" sx={{p: 2}}>
                            </Box>
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={4}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: button === 3 ? "black" : ''
                                    }}
                                    component={RouterLink} to={"/newasset"} onClickCapture={play}
                                    onClick={e => handleChange(e, 3, "My Assets")}>
                                    {
                                        JSON.parse(secureLocalStorage.getItem('datum_count')) > 0 ?
                                            <img
                                                alt="asset"
                                                src='/static/images/icons/asset/Datum.svg'
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    border: 'black'
                                                }}
                                            /> :
                                            <MyAssets/>
                                    }
                                    <Typography variant={"body2"}>
                                        Assets
                                    </Typography>
                                </IconButton>
                            </Box>
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={5}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: button === 4 ? "black" : ''
                                    }}
                                    component={RouterLink} to={"/payments"} onClickCapture={play}
                                    onClick={e => handleChange(e, 4, 'My Payments')}>
                                    <MyPayments style={{width: '20px', height: '20px'}}/>
                                    <Typography variant={"body2"}>
                                        Payments
                                    </Typography>
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </div>
            </AppBar>
            <OrderDialog open={open} handleClose={handleClose}/>
        </Hidden>
    )
}

BottomBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}

BottomBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};
export default BottomBar

