import _ from 'lodash';
import {colors, responsiveFontSizes} from '@mui/material';
import {createTheme} from "@mui/material/styles";
import typography from './typography';
import {THEMES} from "../costants";

const baseOptions = {
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32
            }
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)'
            }
        }
    }
};

const themesOptions = [
    {
        name: THEMES.LIGHT,
        typography,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600]
                    }
                }
            }
        },
        palette: {
            type: 'light',
            action: {
                active: colors.blueGrey[600]
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white
            },
            primary: {
                main: '#454545'
            },
            secondary: {
                main: '#737373'
            },
            text: {
                primary: '#454545',
                secondary: '#737373'
            },
        },
    },
    {
        name: THEMES.ONE_DARK,
        palette: {
            type: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)'
            },
            background: {
                default: '#282C34',
                dark: '#1c2025',
                paper: '#282C34'
            },
            primary: {
                main: '#8a85ff'
            },
            secondary: {
                main: '#8a85ff'
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb'
            },
        },
    },

];

export const createMuiTheme = (config = {}) => {
    let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        [themeOptions] = themesOptions;
    }

    let theme = createTheme(
        _.merge({}, baseOptions, themeOptions, {direction: config.direction})
    );

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};