import React from 'react'
import PropTypes from 'prop-types';

const BellIcon = ({style, className, ...props}) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.95153 7.31252C3.9506 6.64566 4.0816 5.9852 4.33701 5.36919C4.59241 4.75318 4.96716 4.19379 5.43968 3.72323C5.9122 3.25267 6.47315 2.88024 7.09022 2.6274C7.70729 2.37456 8.36828 2.24631 9.03513 2.25002C11.8195 2.27111 14.0484 4.58439 14.0484 7.3758V7.87502C14.0484 10.3922 14.5758 11.8547 15.0398 12.6563C15.0891 12.7416 15.1151 12.8385 15.1152 12.937C15.1153 13.0356 15.0894 13.1325 15.0403 13.2179C14.9912 13.3034 14.9205 13.3744 14.8352 13.4239C14.75 13.4734 14.6532 13.4997 14.5547 13.5H3.44528C3.34671 13.4997 3.24996 13.4734 3.16473 13.4239C3.07949 13.3744 3.00877 13.3034 2.95963 13.2179C2.9105 13.1325 2.88468 13.0356 2.88477 12.937C2.88485 12.8385 2.91084 12.7416 2.96013 12.6563C3.42419 11.8547 3.95153 10.3922 3.95153 7.87502V7.31252Z"
                stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M6.75 13.5V14.0625C6.75 14.6592 6.98705 15.2315 7.40901 15.6535C7.83097 16.0754 8.40326 16.3125 9 16.3125C9.59674 16.3125 10.169 16.0754 10.591 15.6535C11.0129 15.2315 11.25 14.6592 11.25 14.0625V13.5"
                stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

BellIcon.propTypes = {
    fill: PropTypes.string
};

export default BellIcon
