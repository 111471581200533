import {reposWebApi} from "./customerAPI";
import {refreshToken} from "./sessionAction";
import qs from "querystring";
import secureLocalStorage from "react-secure-storage";

export const DATA_FOR_PRODUCT = 'DATA_FOR_PRODUCT'
export const PRODUCT_DETAIL_SENT = "PRODUCT_DETAIL_SENT"
export const PRODUCT_DETAIL_SENT_FAIL = "PRODUCT_DETAIL_SENT_FAIL"
export const PRODUCT_PAYMENT_LOADING = "PRODUCT_PAYMENT_LOADING";
export const GET_PRODUCT_PAYMENT = 'GET_PRODUCT_PAYMENT'
export const GET_PRODUCT_PAYMENT_FAIL = 'GET_PRODUCT_PAYMENT_FAIL'
export const UPDATE_LOADING = "UPDATE_LOADING"
export const UPDATE_RATE = "UPDATE_RATE"
export const UPDATE_RATE_FAIL = "UPDATE_RATE_FAIL"

export const getDataForProduct = (formData) => {
    return (dispatch) => {
        dispatch({
            type: DATA_FOR_PRODUCT,
            payload:formData
        })
    }
};

export const addTataLead = (formData) => {
    return async (dispatch) => {

        reposWebApi.post('/utilities/add_tata_lead', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(res => {
            if (res.status === 200) {
                dispatch({
                    type: PRODUCT_DETAIL_SENT
                })
            }
        }).catch((error) => {
            if (error.response.status === 202) {
                dispatch({
                    type: PRODUCT_DETAIL_SENT_FAIL
                })
            }
        })
    }
}

export const initiatePayment = ( value,setIsPay) => {
    return (dispatch) => {
        dispatch({
            type: PRODUCT_PAYMENT_LOADING
        })
        reposWebApi.post('/payment/tata_initiate_payment', qs.stringify(value), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            setIsPay(true);
            dispatch({
                type: GET_PRODUCT_PAYMENT,
                payload: response?.data
            })
        }).catch((error) => {
            dispatch({
                type: GET_PRODUCT_PAYMENT_FAIL,
            })
        })
    }
}




export const updateRate = ( value,setIsPay) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LOADING
        })
        reposWebApi.post('/utilities/update_tata_lead', qs.stringify(value), {
            headers: {
                Authorization: 'Bearer ' + secureLocalStorage.getItem("access_token")
            }
        }).then(response => {
            setIsPay(true);
            dispatch({
                type: UPDATE_RATE,
                payload: response?.data
            })
        }).catch((error) => {
            dispatch({
                type: UPDATE_RATE_FAIL,
            })
        })
    }
}