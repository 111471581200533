import * as actionTypes from "../actions";


var initialState = {
    assetList: [],
    status: [],
    isLoading: true,
    rating: [],

};

const complaintReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_LIST_FOR_COMPLAINT: {

            return {
                ...state,
                isLoading: false,
                assetList: action?.payload?.results
            };
        }
        case actionTypes.ASSET_FOR_COMPLAINT_LOADING: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.GET_ASSET_LIST_FOR_COMPLAINT_FAIL: {

            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.TICKET_DETAILS_LOADING: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.TICKET_DETAILS: {

            return {
                ...state,
                isLoading: false,
                status: action?.payload
            };
        }
        case actionTypes.TICKET_DETAILS_FAIL: {

            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.RATING_DETAILS_LOADING: {

            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.RATING_DETAILS: {

            return {
                ...state,
                isLoading: false,
                rating: action?.payload
            };
        }
        case actionTypes.RATING_DETAILS_FAIL: {

            return {
                ...state,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default complaintReducer;