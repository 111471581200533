import * as actionTypes from '../actions';

var initialState = {
    isLoading: false,
    energyScore: ''

};

const energyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ENERGY_SCORE_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actionTypes.GET_ENERGY_SCORE: {
            return {
                ...state,
                isLoading: false,
                energyScore: action?.payload
            }
        }
        case actionTypes.GET_ENERGY_SCORE_FAIL: {
            return {
                ...state,
                isLoading: false,
                energyScore: action?.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default energyReducer;
