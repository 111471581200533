import React from 'react'
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const OrderDialog = ({open, handleClose, item, className, onMobileNavOpen, ...rest}) => {

    const classes = useStyles();
    return (
        <div className={clsx(classes.root, classes.appBarTransparent, className)}
             style={{backgroundColor: "#ffffff"}} {...rest}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Box display="flex" justifyContent="center">
                        <Paper elevation={0} style={{marginTop: '-10px'}}>
                            <IconButton onClick={handleClose} style={{marginTop: '-10px'}}>
                                <CloseIcon/>
                            </IconButton>
                        </Paper>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant={"overline2"}
                                    style={{alignSelf: 'center', textShadow: '0px 0px, 0px 0px, 0px 0px'}}>
                            SELECT SERVICE
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="center">
                                <IconButton
                                    onClick={handleClose}
                                    component={RouterLink}
                                    to={{
                                        pathname: `/NewOrders`,
                                        state: {
                                            orderType: 0,
                                            assetData: item
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <img alt={'close'} src="/static/images/order/DDD.svg"/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <Typography variant={"overline2"}>
                                                    Door Step Delivery
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="center">
                                <IconButton
                                    onClick={handleClose}
                                    component={RouterLink}
                                    to={{
                                        pathname: `/NewOrders`,
                                        state: {
                                            orderType: 1,
                                            assetData: item
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <img alt={'close'} src="/static/images/order/Pump.svg"/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <Typography variant={"overline2"}>
                                                    Self Pickup
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default OrderDialog