export default {
    h1: {
        fontWeight: 500,
        fontSize: 35,
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: 500,
        fontSize: 29,
        letterSpacing: '-0.24px'
    },
    h3: {
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: '-0.06px'
    },
    h4: {
        fontWeight: 900,
        fontSize: 20,
        letterSpacing: '-0.06px'
    },
    h5: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '-0.05px'
    },
    overline: {
        fontSize: 11,
        fontWeight: 600
    },
    overline2: {
        fontSize: 12,
        fontWeight: 900
    },
    tab: {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '-0.05px'
    },
    subtitle1: {
        fontSize: 11,
        fontWeight: 200
    },
    subtitle2: {
        fontSize: 9,
        fontWeight: 900
    },
    bodyForNotify: {
        fontSize: 11,
        fontWeight: 900
    },
    bodyForNotify2: {
        fontSize: 14,
        fontWeight: 900
    },
    errorLabel: {
        fontSize: 11,
        fontWeight: 400
    },
};
